@import "../../assets/styles/scss/variables";

.member-bloc {
  color: $color-text;

  h2 {
    font-weight: 600;
    font-family: $font-base;
    position: relative;
    padding-bottom: .4rem;
    margin-bottom: .5rem;

    &::after {
      content: "";
      width: $big;
      height: 1px;
      background-color: $color-gold;
      position: absolute;
      left: 0;
      bottom: 0;
    }
  }

  p.domain {
    font-size: $xsmall;
    color: $color-gray;
    text-transform: uppercase;
    font-weight: 500;
  }

  .img-container {
    overflow: hidden;
    margin-bottom: $xsmall;
    border-radius: $rounded;
    display: flex;
    
    img {
      border-radius: $rounded;
      width: 100%;
      transition: all .2s linear;
    }
  }

  &:hover {

    .img-container {

      img {
        transform: scale(1.05);
      }
    }
  }
}
