//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //18px
  line-height: $text-base-line-height;
  font-weight: normal;
  color: $color-text;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-title;
  font-weight: 400;
}

button {
  all: unset;
}

.is-hidden,
.hidden {
  display: none !important;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: normal;
}

p,
li,
.regular {
  font-size: clamp(0.9rem, 1vw, 20px);
  line-height: 166%;
  font-weight: normal;
}

p {
  margin-top: 1.8rem;
  font-weight: 400;

  &:first-child {
    margin-top: 0;
  }

  strong {
    font-weight: 500;
  }
}

h6,
.medium {
  font-size: $medium;
  line-height: normal;
}

h5,
.big {
  font-size: $big;
  line-height: 124%;
}

h4,
.large:not(input):not(textarea) {
  font-size: clamp($large, 1vw, $large);
  line-height: 135%;
}

h3,
.massive {
  font-size: clamp($massive, 2vw, $massive);
  line-height: 140%;
}

h2,
.huge {
  font-size: clamp(1.9rem, 3vw, $huge);
  line-height: 123%;
}

h1,
.gigantic {
  font-size: clamp(2.5rem, 4vw, $gigantic);
  line-height: 111%;
}

.fancy-title {
  padding-top: 1.3rem;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 5rem;
    height: 2px;
    background-color: $color-gold;
  }
}

#page-content {

  p {
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
  
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.btn-primary {
  display: flex;
  width: fit-content;
  color: $color-gold;
  font-family: $font-title;
  padding: .5rem .7rem;
  text-align: center;
  border-bottom: 2px solid $color-gold;
  transition: all .2s linear;

  &:hover {
    color: $color-gold-dark;
    border-color: $color-gold-dark;
  }
}

.btn-back {
  color: $color-gold-dark;
  transition: all .2s linear;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;

  &::before {
    content: "\f177";
    font-family: $fontawesome;
    font-weight: 100;
    font-size: 1.2rem;
  }

  &:hover {
    color: $color-gold;
  }
}

.btn-next {
  color: $color-gold-dark;
  transition: all .2s linear;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: .5rem;

  &::before {
    content: "\f178";
    font-family: $fontawesome;
    font-weight: 100;
    font-size: 1.2rem;
  }

  &:hover {
    color: $color-gold;
  }
}

.ts-wrapper {
  
  .ts-control {
    background-color: white;
    padding: .4rem 1.2rem !important;
    position: relative;

    &::after {
      content: "\f107";
      font-family: $fontawesome;
      font-weight: 400;
      color: $color-gold-dark;
      font-size: 1rem;
      position: absolute;
      top: 50%;
      right: $xsmall;
      transform: translateY(-50%);
      transition: all .2s linear;
    }
  }
  
  .ts-control,
  .ts-dropdown {
    color: $color-gold-dark;
    font-size: 1rem;
    line-height: normal;
    border: 1px solid $color-gold;
    border-radius: $rounded;
    box-shadow: none;

    input {
      font-size: inherit;
      line-height: inherit;
    }
  }

  .ts-dropdown {
    margin-top: 0;

    .option {
      padding: .4rem 1.2rem;
    }

    .selected {
      color: $color-gold-dark;
      background-color: $color-gold-light;

      &.active {
        color: $color-gold-dark;
      background-color: $color-gold-light;
      }
    }

    .active {
      color: $color-gold-dark;
      background-color: white;
    }
  }

  &.dropdown-active {

    .ts-control,
    .ts-dropdown {
      border-color: $color-gold-dark;
    }
      
    .ts-control {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      &::after {
        transform: translateY(-50%) rotate(180deg);
      }
    }

    .ts-dropdown {
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
}

#searchbar-group {
  position: relative;

  button {
    position: absolute;
    top: 50%;
    right: 1.2rem;
    transform: translateY(-50%);
    z-index: 1;
    font-size: $small;
    font-weight: 400;
    color: $color-gold-dark;
    background-color: transparent;
    cursor: pointer;
  }

  input[type=search] {
    width: 100%;
  }
}

input[type=search] {
  padding: .5rem 1.2rem;
  padding-right: 3.5rem;
  background-color: $color-gold-light;
  color: $color-gold-dark;
  border-radius: $rounded;
  border: 1px solid $color-gold-light;
  font-size: 1rem;
  line-height: normal;
  outline: none;

  &:focus,
  &:focus-visible {
    border: 1px solid $color-gold-dark;
  }

  &::placeholder {
    color: inherit;
  }
}

.loader-content {
  display: none;
  margin: 2rem 0;
  
  .loader {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    width: 15px;
    aspect-ratio: 1;
    border-radius: 50%;
    animation: loading 1s infinite linear alternate;
  }
}

@keyframes loading {
  0%  {box-shadow: 20px 0 $color-gold, -20px 0 $color-gold-light;background: $color-gold }
  33% {box-shadow: 20px 0 $color-gold, -20px 0 $color-gold-light;background: $color-gold-light}
  66% {box-shadow: 20px 0 $color-gold-light,-20px 0 $color-gold; background: $color-gold-light}
  100%{box-shadow: 20px 0 $color-gold-light,-20px 0 $color-gold; background: $color-gold }
}

// #empty-msg {
//   display: none;
// }

.languages-list {
  list-style: none;
  padding: 0;
  padding-left: 1rem;
  margin: 0;
  display: flex;
  gap: 1rem;
  
  li {
    font-weight: bold;
    text-transform: uppercase;
  }
}