@import "../../assets/styles/scss/variables";

#page-header.publication-header {
  margin-top: 1.4rem;
  margin-bottom: 4.7rem;
  max-width: 1440px;
  margin-inline: auto;

  .top-header {
    margin-left: 10%;
    max-width: 60%;

    @media (max-width:991px) {
      max-width: 75%;
    }

    @media (max-width:767px) {
      max-width: 83%;
    }

    @media (max-width:576px) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  @media (max-width:991px) {
    max-width: 100%;
  }

  h1 {
    margin-top: 1.8rem;
    margin-bottom: 1rem;
  }

  .img-container {
    margin-top: 3rem;
    border-radius: $rounded;
    display: none;

    img {
      width: 100%;
    }
  }
}