//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: white;
	margin-top: 10rem;

	#footer-top {
		padding-bottom: 2.2rem;
		position: relative;

		.aiv-shape {
			position: absolute;
			right: -1.3rem;
			bottom: -.5rem;
			opacity: 5%;
			width: 33rem;
			height: auto;

			@media (max-width:991px) {
				width: 22rem;
			}

			@media (max-width:576px) {
				max-width: 75%;
			}
		}

		.container {
			display: flex;
			flex-direction: column;
			gap: 2rem;

			.top {

				span.huge {
					line-height: 100%;
					margin-bottom: 2rem;
					font-family: $font-title;
					display: inline-block;
					max-width: 17rem;
				}
			}

			.bottom {
				display: grid;
				grid-template-columns: 22rem auto;
				gap: 2rem;
				align-items: flex-start;
				
				@media (max-width:767px) {
					grid-template-columns: repeat(2, 1fr);
				}
	
				@media (max-width:576px) {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 3rem;
				}
			}

		}

		address {
			margin-bottom: $large;
		}

		.phone {
			margin-bottom: .5rem;
		}

		.socials {
			margin-top: $huge;
		}

		.menu-container {
			padding: 0;
			margin: 0;
			list-style: none;

			li {
				border-top: 1px solid $color-gold-light;
				width: fit-content;

				&:first-child {
					border-top: none;
				}

				a {
					color: $color-text;
					padding: .4rem 1rem;
					padding-right: .4rem;
					display: inline-block;

					&:hover {
						color: $color-gold;
					}
				}
			}
		}

		#language-switcher {
			margin-top: 1.8rem;

			.languages-list {
				padding-left: 1rem;
			}
		}
	}

	#footer-bottom {
		background-color: $color-gold;
		color: white;
		font-size: $xsmall;
		padding-top: 2rem;
		padding-bottom: $medium;

		@media (max-width:991px) {
			padding-top: .7rem;
			padding-bottom: 1rem;
		}

		.container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 2rem;

			@media (max-width:991px) {
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				gap: .5rem;
			}
		}

		p {
			margin: 0;
		}

		a {
			color: inherit;
		}
	}

}
.powered {
	position: relative;
	color: inherit;
	&:hover {
		color: inherit;
		text-decoration: none;
		#logo-alt-footer-trois {
			fill: white;
			transform: scale(1.2);
		}
		#logo-alt-footer-deux {
			fill: white;
			transform: scale(1.2);
		}
		#logo-alt-footer-un {
			fill: white;
			transform: scale(1.2);
		}
		&:after {
			width: 100px;
		}
	}
	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;
		#logo-alt-footer-trois {
			fill: transparent;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-deux {
			fill: transparent;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}
		#logo-alt-footer-un {
			fill: transparent;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}
	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: white;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}
