//
// Header Styles
// --------------------------------------------------

#header {
	width: 100%;
	background-color: white;
	padding: 2.2rem 0;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20;
	transition: all 0.4s linear;

	@media (max-width:767px) {
		padding: 1rem 0;

		#logo {
			max-width: 8rem;
		}
	}

	&.sticky {
		padding: .8rem 0;
		#header-extras {
			opacity: 0;
			margin-top: -40px;
		}

		#logo {
			max-width: 7rem;

			@media (max-width:1441px) {
				max-width: 6rem;
			}

			@media (max-width:1199px) {
				max-width: 5rem;
			}

			@media (max-width:991px) {
				max-width: 8rem;
			}
		}

		@media (max-width:991px) {
			padding: 2.2rem 0;
		}

		@media (max-width:767px) {
			padding: 1rem 0;
		}
	}

	#menu-mobile-trigger {
		display: none;
		transition: transform .2s linear;
		.trigger-bar {
			display: block;
			width: 2rem;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-gold-dark;
			&:not(:first-child) {
				margin-top: 10px;
			}
		}

		&.is-active {
			transform: rotate(90deg);
		}

		@media (max-width:991px) {
			display: block;
		}
	}

	#logo {
		width: 100%;
		max-width: 10rem;
		transition: all .4s linear;
		a {
			display: block;
		}

		@media (max-width:1441px) {
			max-width: 8rem;
		}

		@media (max-width:1199px) {
			max-width: 6rem;
		}

		@media (max-width:991px) {
			max-width: 8rem;
		}
	}

	#header-content {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;

		@media (max-width:991px) {
			align-items: center;
		}
	}

	#menu {
		transition: all 0.4s linear;
		#menu-main-container {
			display: inline-block;
			width: 100%;
			ul {
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				display: flex;
				align-items: baseline;
				justify-content: flex-end;
				gap: 2.5rem;
				li {
					position: relative;
					a {
						font-size: $medium;
						font-weight: 500;
						line-height: normal;
						display: block;
						color: $color-text;
						position: relative;

						&::before,
						&::after {
							content: "";
							position: absolute;
							left: 0;
							width: 100%;
							height: 2px;
							background-color: transparent;
						}

						&::before {
							top: -.5rem;
						}

						&::after {
							bottom: -.5rem;
						}
						&:hover {
							color: $color-link-hover;

							&::before,
							&::after {
								background-color: $color-gold;
							}
						}
					}
					&:last-child {
						margin-right: 0;
					}
					&.current-menu-item,
					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {
						> a {
							color: $color-gold-dark;

							&::before,
							&::after {
								background-color: $color-gold;
							}
						}
					}
					.sub-menu {
						opacity: 0;
						height: 0;
						padding: 0;
						position: absolute;
						text-align: left;
						margin-left: -1rem;
					    transform: scale(0.95) translateY(1rem);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						gap: 0;
						overflow: hidden;
						li {
							white-space: nowrap;
							margin: 0;
							min-width: 13rem;
							width: 100%;
							position: relative;

							&::after {
								content: "";
								position: absolute;
								left: 50%;
								bottom: 0;
								transform: translateX(-50%);
								height: 1px;
								width: calc(100% - 1rem);
								background-color: $color-gold;
							}
							a {
								display: block;
								color: $color-gold-dark;
								background-color: $color-gold-light;
								padding: $small $xsmall;
								&:hover {
									color: white;
									background-color: $color-gold;
								}

								&::before,
								&::after {
									display: none;
								}
							}
							&:last-child {
								margin-bottom: 0;

								&::after {
									display: none;
								}
							}
							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-gold-dark;
								}
							}
						}
					}
					&.menu-item-has-children {
						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f107';
							display: block;
							position: absolute;
							top: 50%;
							right: -1.1rem;
							font-weight: 400;
							font-size: $small;
							line-height: 1;
							margin-top: 2px;
							transform: translateY(-50%);
							transform-origin: center;
							color: $color-text;
							transition: transform 0.25s;
						}
						&:hover {
							&:after {
								transform: translateY(-50%) rotate(-180deg);
								margin-top: 0;
							}
							.sub-menu {
								height: auto;
								opacity: 1;
							  transform: scale(1) translateY(0);
								padding-top: 1rem;
							}
						}

						&.current-menu-item {
							&::after {
								color: $color-gold-dark;
							}
						}
					}
				}

				@media (max-width:1441px) {
					gap: 1.4rem;

					li {

						a {
							font-size: .9rem;
						}
					}
				}

				@media (max-width:1199px) {

					li {

						a {
							font-size: .8rem;
						}
					}
				}
			}
		}
	}

	#header-extras {
		position: absolute;
		top: 0;
		right: 0;
		transition: all 0.4s linear;
		margin-top: 0;

		ul.socials {
			gap: .5rem;

			li {

				a {
					font-size: .7rem;
					width: 1.4rem;
					height: 1.4rem;
					min-height: 1.4rem;
					min-width: 1.4rem;
				}
			}
		}

		.languages-list {
			margin-left: 1rem;
		}

		@media (max-width:991px) {
			display: none;
		}
	}
}

.mm-ocd--open {
	top: 150px;
	height: calc(100vh - 150px);
	width: 100vw;

	@media (max-width:767px) {
		top: 95px;
		height: calc(100vh - 95px);
	}

	.mm-ocd__content {
		width: 100vw;
		max-width: 100vw;
		padding: 0 1.5rem;
	}

	.mm-spn {
		background-color: white;
	}

	.mm-ocd__backdrop {
		display: none;
	}

	a {
		color: $color-text;
		padding: 1rem;
		font-size: 1.1rem;
		line-height: normal;
	}

	.mm-spn li {
		&:last-child {

			&::after {
				display: none;
			}
		}
	}

	.mm-spn li:after {
		opacity: 1;
		border-color: $color-gold;
		margin: 0;
    width: calc(100% - 2rem);
    margin-left: 1rem;
	}

	.mm-spn.mm-spn--navbar ul:before {
		display: none;
	}

	.mm-spn a:not(:last-child):after {
		display: none;
	}

	.mm-spn.mm-spn--navbar:after {
		opacity: 1;
		color: $color-gold-dark;
		text-align: left;
	}

	.mm-spn.mm-spn--navbar:before {
		opacity: 1;
		border-top-color: $color-gold-dark;
		border-left-color: $color-gold-dark;
	}

	.mm-spn li:before {
		border-top-color: $color-gold;
		border-right-color: $color-gold;
		opacity: 1;
		top: 50%;
		transform: translateY(-50%) rotate(45deg);
	}
}

body {
	&.single-member {

		#header {

			#menu {

				#menu-main-container {

					ul {

						li {
							&.is-member {
								
								a {
									color: $color-gold;
	
									&::before, &::after {
										background-color: $color-gold;
									}
								}

							}
						}
					}
				}
			}
		}
	}
	&.single-travel {

		#header {

			#menu {

				#menu-main-container {

					ul {

						li {
							&.is-travel {
								
								a {
									color: $color-gold;
	
									&::before, &::after {
										background-color: $color-gold;
									}
								}

							}
						}
					}
				}
			}
		}
	}

	&.single-symposium {

		#header {

			#menu {

				#menu-main-container {

					ul {

						li {
							&.is-symposium {
								
								a {
									color: $color-gold;
	
									&::before, &::after {
										background-color: $color-gold;
									}
								}

							}
						}
					}
				}
			}
		}
	}

	&.single-publication {

		#header {

			#menu {

				#menu-main-container {

					ul {

						li {
							&.is-publication {
								
								a {
									color: $color-gold;
	
									&::before, &::after {
										background-color: $color-gold;
									}
								}

							}
						}
					}
				}
			}
		}
	}

	&.single-conference {

		#header {

			#menu {

				#menu-main-container {

					ul {

						li {
							&.is-conference {
								
								a {
									color: $color-gold;
	
									&::before, &::after {
										background-color: $color-gold;
									}
								}

							}
						}
					}
				}
			}
		}
	}
}