@import "../../assets/styles/scss/variables";

#page-header.conference-header {
  margin-top: 1.4rem;
  margin-bottom: 4.7rem;
  max-width: 1440px;
  margin-inline: auto;

  .top-header {
    margin-left: 10%;
    max-width: 60%;

    @media (max-width:991px) {
      max-width: 75%;
    }

    @media (max-width:767px) {
      max-width: 83%;
    }

    @media (max-width:576px) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  @media (max-width:991px) {
    max-width: 100%;
  }

  h1 {
    margin-top: 1.8rem;
    margin-bottom: 1rem;
  }

  ul.infos {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: $color-gray;
    gap: 1.2rem;
    list-style: none;
    padding: 0;
    margin: 0;

    ul.tags {
      display: flex;
      flex-wrap: wrap;
      gap: .7rem;
      padding: 0;
      margin: 0;
      list-style: none;
    }
  
    .tag {
      display: flex;
      width: fit-content;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      padding: .1rem .4rem;
      border-radius: 1rem;
      font-size: $xsmall;
      color: $color-gold-dark;
      background-color: $color-gold-light;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.2rem;

      &:not(:first-child):not(.tag)::before {
        content: "";
        width: .45rem;
        height: .45rem;
        border-radius: 50%;
        background-color: $color-gold;
      }
    }

    @media (max-width:576px) {
      flex-direction: column;
      gap: 1rem;

      li {
        &::before {
          display: none;
        }
      }
    }
  }

  p.medium {
    margin-top: 1.5rem;
  }

  .img-container {
    margin-top: 3rem;
    border-radius: $rounded;

    img {
      width: 100%;
    }
  }
}