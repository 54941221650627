//
// General Styles
// --------------------------------------------------

html {
	margin: 0;
	padding: 0;
	text-size-adjust: 100%;
	overflow-x: hidden !important;
}

body {
	font-family: $font-base;
	font-size: $text-base-size;
	line-height: $text-base-line-height;
	color: $color-text;
	background-color: #ffffff;
	margin: 0;
	padding: 0;
	overflow-x: hidden !important;
}

* {
	box-sizing: border-box;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

iframe {
	max-width: 100%;
}

a img,
img {
	border: none;
	max-width: 100%;
	height: auto;
}

a {
	color: $color-link;
	cursor: pointer;
	text-decoration: none;
	transition: all .2s linear;

	&:hover {
		outline: none;
		color: $color-link-hover;
		text-decoration: none;

		i {
			color: inherit;
		}
	}

	&:focus {
		outline: none;
		text-decoration: none;
	}

	i {
		color: inherit;
		line-height: normal !important;
		transition: all .2s linear;
	}
}

p,
blockquote {
	margin-bottom: 20px;

	&:last-child {
		margin-bottom: 0;
	}
}

blockquote {
	color: $color-gold-dark;
  font-size: clamp($massive, 2vw, $massive);
	line-height: 117%;
	font-weight: 500;
	position: relative;
	max-width: 75%;
	margin-inline: auto;
	margin-top: 2.2rem;
	margin-bottom: 2.2rem;

	&::before {
		content: url('../images/quote-icon.svg');
		width: 2.3rem;
		height: auto;
		position: absolute;
		left: -4.6rem;
		top: 0;
	}

	p {
		font-size: inherit;
		line-height: inherit;
		font-weight: inherit;
	}
}

table {
	margin-top: $margin-base;
	margin-bottom: 15px;
	border: inherit;
	border-color: inherit;
	border-spacing: 0;
	border-collapse: collapse;

	tr {
		border: inherit;
		border-color: inherit;

		td {
			border-top: 1px solid $color-gray-lighter !important;
			padding: 1rem !important;
			vertical-align: middle !important;
		}
	}
}

address {
	font-style: normal;
}

.gform_validation_errors {
	padding: 0;
	color: $color-red;
	margin-bottom: 25px;
	font-size: 14px;

	.gform_submission_error {
		color: $color-red;
		font-size: 24px;
		line-height: normal;
		margin-bottom: 10px;
	}

	ol {
		padding: 0;
		list-style-position: inside;
	}

	.gform_validation_error_link {
		color: $color-red;
	}
}

.validation_message {
	color: $color-red;
	clear: both;
}

.gform_heading {

	.gform_required_legend {
		display: none;
	}
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

form {
	.gform_body {
		display: table;
		width: 100%;
	}

	.gform_footer {
		margin-top: 2rem;
		display: flex;
		align-items: flex-start;
		justify-content: flex-end;

		input {
			background-color: transparent;
			font-family: $font-title;
			font-size: 1rem;
			line-height: normal;
			border-top: 2px solid $color-gold;
			border-bottom: 2px solid $color-gold;
			padding: .5rem .8rem;
			color: $color-gold;

			&:hover {
				background-color: $color-gold;
				color: white;
				border-top: 2px solid $color-gold !important;
				border-bottom: 2px solid $color-gold !important;
			}
		}
	}

	.gform_hidden {
		display: none !important;
	}

	.gfield_required {
		margin-left: 5px;
		color: $color-red;
		font-weight: bold;
		transition: all .2s linear;
	}

	.gform_fields {
		margin: 0;
		padding: 0;
		list-style: none;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: $small;
		row-gap: $small;

		.uploader-btn {
			display: flex;
			align-items: center;
			justify-content: flex-start;

			.uploader-filename {
				margin-right: 60px;
				min-width: 150px;
			}

			button {
				background-color: $color-primary;
				color: $color-text;
				border: 2px solid $color-primary;
				box-shadow: none;

				&:hover {
					background-color: $color-link-hover;
					color: $color-text;
					border: 2px solid $color-primary !important;
				}

				&:focus {
					border: 2px solid $color-link-hover !important;
				}
			}
		}

		.gfield_list_group {

			.gfield_list_icons {
				margin-top: 10px;
			}
		}

		.gfield {
			margin: 0;
			padding: 0;
			position: relative;
			font-size: 18px;
			line-height: normal;

			&.gfield--width-full {
				grid-column: span 2;
			}

			&.gfield_visibility_hidden {
				display: none;
			}

			&.hidden_label {
				.gfield_label {
					display: none;
				}
			}

			&.field_sublabel_above {
				margin-bottom: 20px;
			}

			&.file-input {
				display: none;
			}

			&.gfield_error {

				input,
				textarea {
					border: 1px solid $color-red;
					margin-bottom: .5rem;
				}
			}

			.gfield_label_before_complex {
				display: none;
			}

			.ginput_complex {
				display: inline-block;
				width: 100%;

				span {
					display: inline-block;
					width: 100%;

					&.name_first,
					&.ginput_left {
						width: 50%;
						padding-right: 15px;
						float: left;
					}

					&.name_last,
					&.ginput_right {
						width: 50%;
						padding-left: 15px;
						float: left;
					}

					&.address_line_1 {
						margin-bottom: 20px;
					}
				}
			}

			.gchoice {
				width: fit-content;
				transition: all .2s linear;

				&:not(:last-child) {
					margin-bottom: 4px;
				}

				input {
					visibility: hidden;
					margin: 0;
					margin-right: 0;
				}

				label {
					padding-left: 5px;
					font-weight: 400;
					position: relative;
					cursor: pointer;
					left: initial;
					top: initial;
					color: $color-text;
					transition: all .2s linear;

					&::before {
						position: absolute;
						top: 50%;
						left: -20px;
						transform: translateY(-50%);
						content: "";
						width: 16px;
						height: 16px;
						border-radius: 50%;
						border: 2px solid $color-primary;
						background-color: transparent;
						transition: all .2s linear;
					}
				}

				.gfield-choice-input:checked~label {
					font-weight: 600;

					&::before {
						background-color: $color-primary !important;

					}
				}

				&:hover {

					label {
						&::before {
							background-color: $color-primary;
						}
					}
				}
			}

			.gfield_checkbox {

				.gchoice {

					label {

						&::before {
							position: absolute;
							top: 50%;
							left: -20px;
							transform: translateY(-50%);
							content: "";
							width: 16px;
							height: 16px;
							border-radius: 3px;
							border: 2px solid $color-primary;
							background-color: transparent;
							transition: all .2s linear;
						}
					}
				}
			}
		}
	}

	label,
	.gfield_label {
		font-size: $xsmall;
		line-height: inherit;
		color: #CACACA;
		text-transform: uppercase;
		margin-bottom: .5rem;
		display: inline-block;
	}

	.input-group-addon {
		background-color: $color-gray-darker;
		color: $color-primary;
		border: 0;
	}

	fieldset {
		border: none;
	}

	select {
		padding: 8px 10px;
		background-color: white;
		height: 42px;
		color: $color-gray;
		width: 100%;
		border: 0;
		border: 1px solid $color-gray-lighter;
	}

	select[multiple] {
		height: auto;
	}

	input,
	textarea {
		width: 100%;
		border: 0;
		background-color: $color-gold-light;
		border-radius: 5px;
		color: $color-gold-dark;
		padding: .5rem $medium;
		appearance: none;
		resize: none;
		font-size: 1rem;
		line-height: normal;
		font-family: inherit;

		&::placeholder {
			font-size: inherit;
			color: inherit;
		}

		&:focus {
			outline: none !important;
			box-shadow: none !important;
			border: 0 !important;

			&::placeholder {
				color: inherit;
				opacity: .5;
			}
		}
	}

	input[type="checkbox"],
	input[type="radio"] {
		width: auto;
		appearance: inherit;
	}

	input[type="checkbox"] {
		appearance: checkbox;
	}

	input[type="radio"] {
		appearance: radio;
	}

	.gfield_description {
		margin-top: 0;
		margin-bottom: 10px;
		font-size: 14px;
		font-style: italic;
	}
}

button {
	all : unset;
}

button,
input[type="submit"],
button[type="submit"],
.product .cart .single_add_to_cart_button,
#error-template .button {
	display: inline-block;
	width: auto;
	color: $color-text;
	background-color: $color-link;
	border: 0;
	border-radius: 0;
	padding: 10px 20px;
	font-size: $text-base-size;
	text-decoration: none;
	cursor: pointer;
	line-height: inherit;
	text-align: center;
	transition: all .2s linear;

	&.success {
		background-color: $color-green;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-green, 5%);
		}
	}

	&.error {
		background-color: $color-red;
		color: $color-text;

		&:hover {
			color: $color-text;
			background-color: lighten($color-red, 5%);
		}
	}

	&:hover {
		color: $color-text;
		background-color: $color-link-hover;
		text-decoration: none;
		border: 0 !important;
	}

	&:focus {
		outline: none !important;
		text-decoration: none;
		border: 0 !important;
	}
}

.container,
.container-fluid {
	position: relative;
	margin-inline: auto;

	@media (max-width:576px) {
		max-width: 85vw !important;
	}
}

.container-fluid {
	width: 100vw;
	max-width: 3840px;
}

.container {
	width: 90vw;
	max-width: 2880px;

	&.small-container {
		width: 83%;
		max-width: 52rem;

		@media (max-width:767px) {
			width: 100%;
			max-width: 100%;
			padding: 0 1.5rem;
		}

		@media (max-width:576px) {
			max-width: 85vw;
			padding: 0;
		}
	}

	&.smaller-container {
		width: 66%;
		max-width: 52rem;

		@media (max-width:991px) {
			width: 83%;
			max-width: 66rem;
		}

		@media (max-width:767px) {
			max-width: 100%;
			width: 100%;
			padding: 0 1.5rem;
		}

		@media (max-width:576px) {
			max-width: 85vw;
			padding: 0;
		}
	}
}

.screen-reader-text {
	clip: rect(1px, 1px, 1px, 1px);
	position: absolute !important;
	height: 1px;
	width: 1px;
	overflow: hidden;
}

.page-section {
	padding-top: $margin-base;
	padding-bottom: $margin-base;
}

.no-padding-top {
	padding-top: 0;
}

.no-padding-bottom {
	padding-bottom: 0;
}

.no-margin-top {
	margin-top: 0;
}

.no-margin-bottom {
	margin-bottom: 0;
}

#main-content {

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		&:first-child {
			margin-top: 0;
		}
	}
}

.acf-map {
	width: 100%;
	height: 700px;
	border: 0;
	border: 0;
}

.highlight {
	color: #ffffff;
	background-color: $color-primary;
}

#error-template {

	.error-actions {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 3rem;

		.btn-primary {
			@media(max-width:576px) {
				width: 100%;
				text-align: center;
				justify-content: center;
			}
		}
	}
}

#post-share {
	.social-share {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			display: inline-block;
			margin-right: 30px;

			&:last-child {
				margin-right: 0;
			}

			a {
				i {
					font-size: 25px;
				}
			}
		}
	}
}

.loading-spinner {
	width: 50px;
	height: 50px;
	margin: 0 auto;
	float: none;
	background-image: url('../images/ajax-loader.svg');
	background-size: 100%;
	background-position: center;
	background-repeat: no-repeat;
}

#pagination {
	text-align: center;

	.navigation {
		ul {
			padding: 0;
			margin: 0;

			li {
				display: inline-block;

				a {
					color: #ffffff;
					text-decoration: none;
					background-color: $color-gray-dark;
					cursor: pointer;
					padding: 5px 13px;
					border-radius: 5px;
					display: block;

					&:hover {
						background-color: $color-primary;
					}
				}

				&.active {
					a {
						background-color: $color-primary;
					}
				}
			}
		}
	}
}

#cookie-notice {
	.button {
		font-size: 14px;
		padding: 5px 10px;
	}
}

/* WordPress */
.wp-caption,
.gallery-caption {
	color: #666;
	font-size: 13px;
	font-size: 0.8125rem;
	font-style: italic;
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
	font-size: 14px;
}

.bypostauthor>.comment-body>.comment-meta>.comment-author .avatar {
	border: 1px solid #333;
	padding: 2px;
}

.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

img.alignleft {
	float: left;
	margin-right: 1.5em;
}

img.alignright {
	float: right;
	margin-left: 1.5em;
}