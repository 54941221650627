@import "../../assets/styles/scss/variables";

.event-bloc {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $big;
  padding-top: 2.3rem;
  padding-bottom: 3.9rem;
  padding-left: 5.2rem;
  padding-right: 3rem;
  width: 66%;
  max-width: 54rem;

  @media (max-width:1199px) {
    width: 83%;
  }

  @media (max-width:767px) {
    width: 100%;
    padding-top: 1rem;
    padding-left: 3rem;
    padding-bottom: 1rem;
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 1.4rem;
    height: 100%;
    background-color: $color-gold;
    z-index: -1;
    transition: width .2s ease-in-out;

    @media (max-width:767px) {
      width: 1rem;
    }
  }

  &::after {
    content: "\f178";
    font-family: $fontawesome;
    font-weight: 100;
    color: $color-gold;
    font-size: 2rem;
    position: absolute;
    right: .5rem;
    bottom: 1.5rem;
  }

  h2 {
    font-size: 2.4rem;
    line-height: normal;
    color: $color-text;
    font-weight: 400;

    @media (max-width:991px) {
      font-size: 2rem;
    }

    @media (max-width:767px) {
      font-size: 1.8rem;
    }
  }

  ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    color: $color-gray;
  }

  &:hover {
  
    h2, ul, &::after {
      color: white;
    }

    &::before {
      width: 100%;
    }
  }
}

.past {

  .event-bloc {
    padding: 0;
    gap: 0;
    max-width: 100%;
    width: 100%;

    &::before {
      display: none;
    }

    &::after {
      right: 1.4rem;
      bottom: 0;
    }

    .img-container {
      position: relative;
      width: 100%;
      height: auto;
      padding-top: 100%;
      overflow: hidden;
      border-radius: $rounded;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%) scale(1);
      transition: all .2s linear;
    }

    h2 {
      font-size: $big;
      margin-top: .8rem;
      margin-bottom: 1rem;
      max-width: 17rem;
      padding-left: 1.3rem;
    }

    ul {
      padding-left: 1.3rem;
      max-width: calc(100% - 4.7rem);
      margin-top: auto;
      
      li {
        font-size: $small;
      }
    }

    &:hover {
      h2, ul, &::after {
        color: $color-text;
      }

      .img-container {

        img {
          transform: translateY(-50%) scale(1.05);
        }
      }
    }
  }
}