@import "../../assets/styles/scss/variables";

ul.socials {
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: .7rem;
	list-style: none;
	
	a {
		font-size: 1rem;
		color: white;
		background-color: $color-gold;
		width: 2rem;
		height: 2rem;
		min-width: 2rem;
		min-height: 2rem;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		
		&:hover {
			background-color: $color-gold-dark;
		}
	}
}