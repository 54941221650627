@import "../../assets/styles/scss/variables";

.conference-bloc {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 1.61rem 2rem;
  width: 100%;
  gap: $big;
  border-bottom: solid $color-gold 1px;

  &:last-child {
    border-bottom: none;
  }

  .conference-infos {
    display: grid;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: $big;
    width: 80%;
    grid-template-columns: 60px 250px auto;

    .conference-content {
      max-width: 45vw;
    }
  }

  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    background-color: $color-gold;
    z-index: -1;
    transition: width .2s ease-in-out;
  }

  // &::after {
  //   content: "\f178";
  //   font-family: $fontawesome;
  //   font-weight: 100;
  //   color: $color-gold;
  //   font-size: 2rem;
  //   position: absolute;
  //   right: 1.2rem;
  //   bottom: 2.4rem;
  // }

  ul.tags {
    display: flex;
    flex-wrap: wrap;
    gap: .7rem;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .tag {
    display: flex;
    width: fit-content;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    padding: .3rem .6rem;
    border-radius: 1rem;
    font-size: $xsmall;
    line-height: normal;
    color: $color-gold-dark;
    background-color: $color-gold-light;
    text-align: center;
  }

  h2 {
    font-size: 0.88889rem;
    line-height: normal;
    color: $color-text;
    font-weight: 400;
  }

  p {
    font-size: 1.22222rem;
    color: $color-text;
    margin: 0;
  }

  ul.date {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-transform: uppercase;
    color: $color-gray;
  }

  &:hover {

    h2,
    ul,
    &::after,
    p {
      color: white;
    }

    .tag {
      background-color: white;
    }

    &::before {
      width: 100%;
    }
  }

  @media (max-width: 1440px) {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    align-content: flex-start;
    gap: 1rem;
    padding: 1.61rem 1rem;

    .conference-infos {
      display: flex;
      flex-direction: row;
      width: 100%;
      flex-wrap: wrap;
      row-gap: 1rem;

      .conference-content {
        max-width: 100%;
      }

      .conference-title {
        width: calc(100% - 60px - $big);
      }
    }

    .tags {
      position: absolute;
      top: 1.61rem;
      right: 1rem;
    }
  }

  @media (max-width: 767px) {
    padding: 1.61rem .3rem;

    .conference-infos {
      row-gap: 0.3rem;

      .conference-title {
        padding: 1rem 0 0 0;
      }
    
      .date {
        width: 100%;
      }

      .conference-title {
        width: 100%;
      }
    }

    .tags {
      position: absolute;
      top: 1.61rem;
      right: .3rem;
    }
  }
}