//
// Template Styles
// --------------------------------------------------

#main {
    padding-top: 168px; // Header height
    z-index: 15;

    @media (max-width:767px) {
        padding-top: 110px;
    }
}
.cta-btn {
    color: white;
    background-color: $color-gold;
    font-family: $font-title;
    font-size: $massive;
    line-height: normal;
    position: relative;
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem;
    padding-top: 3rem;
    padding-bottom: 7.5rem;
    padding-left: 2.9rem;
    padding-right: 10%;
    display: flex;
    width: fit-content;
    margin-left: auto;

    &::after {
        content: "\f178";
        font-family: $fontawesome;
        font-weight: 100;
        font-size: 2rem;
        position: absolute;
        right: 4.5rem;
        bottom: 3.2rem;
      }

    &:hover {
        background-color: $color-gold-dark;
        color: white;
    }

    @media (max-width:767px) {
        border-radius: 0;
        width: 100%;

        &::after {
            right: 2.5rem;
        }
    }
}

.events-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 4rem;
    row-gap: 3rem;

    @media (max-width:991px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
    }
}

.news-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 3rem;

    @media (max-width:576px) {
        display: flex;
        flex-direction: column;
    }
}

#single-member {

    .grid {
        display: grid;
        grid-template-columns: 40% 50%;
        gap: 2rem;
        justify-content: space-between;

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            gap: 3rem;
            margin-top: 4rem;
        }
    }

    .infos {

        ul {
            padding: 0;
            margin: 0;
            list-style: none;
            
            li {
                display: grid;
                grid-template-columns: 40% auto;
                gap: 2rem;
                font-size: $medium;
                font-weight: 500;
                padding: $xsmall 0;
                border-bottom: 1px solid $color-gold-light;

                &:last-child {
                    border-bottom: none;
                }

                @media (max-width:576px) {
                    display: flex;
                    flex-direction: column;
                    gap: .6rem;
                    margin-bottom: $large;
                }
            }

            span {
                font-size: $xsmall;
                color: $color-gray;
                text-transform: uppercase;
                font-weight: 400;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                gap: $xsmall;

                i {
                    font-size: $small;
                    color: $color-gold;
                }
            }
        }

        .btn-primary.mail {
            margin-top: $gigantic;
        }
    }

    .accordions {
        margin-top: 2rem;

        .accordion {
            display: none;
        }
    }
}

#page-members {

    .filters-wrapper {
        display: grid;
        grid-template-columns: 25% 25% 30%;

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);

            #searchbar-group {
                grid-column: span 2;
                margin-left: 0;
            }
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }

    #members {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 2rem;

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width:767px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
        }
    }
}

#single-event {

    #related-events {
        display: none;
    }
}

#single-publication {

    #page-content {

        p.date {
            text-transform: uppercase;
            color: $color-gray;
            margin-top: 2.5rem;
        }
    }

    #related-events {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;
    }
}

#page-blog {

    .filters-wrapper {
        display: grid;
        grid-template-columns: 25% 25%;
        display: none;

        @media (max-width:1199px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
            align-items: stretch;

            #searchbar-group {
                margin-left: 0;
            }
        }
    }

    #news {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 4.5rem;

        @media (max-width:1024px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
        }
    }

    .news-bloc {
        display: flex;
        flex-direction: column;
        gap: .5rem;

        .fancy-title {
            padding: 0;
            margin-bottom: .8rem;
            max-width: 100%;
      
            &::before {
                display: none;
            }
        }
    }
}

#page-homepage {

    #latest-events {
        margin-top: $margin-base;
        margin-left: 8rem;

        @media (max-width:1440px) {
            margin-left: 0;
        }

        .fancy-title {
            margin-bottom: 3rem;
        }
    }

    #about {
        margin-top: $margin-base;
        display: grid;
        grid-template-columns: auto 49%;

        .content {
            padding-left: 10%;
            padding-right: $margin-base;
            margin-top: $gigantic;

            .fancy-title {
                margin-bottom: 1.8rem;
            }

            .btn-primary {
                margin-top: 2rem;
            }

            .descr {
                padding-left: 5rem;
                max-width: 31rem;
            }

            .img-container {
                margin-left: 5rem;
                margin-top: 4rem;
                width: 115%;
            }

            @media (max-width:1199px) {
                padding-right: 5rem;
                margin-top: $massive;
            }

            @media (max-width:991px) {
                padding: 0;
                width: 85vw;
                margin: 0 auto;
                display: flex;
                flex-direction: column;

                .img-container {
                    order: 1;
                    margin-left: -7.5vw;
                    margin-top: 0;
                    margin-bottom: 2rem;
                    width: 100%;
                }

                h2.fancy-title {
                    order: 2;
                }

                .descr {
                    order: 3;
                    max-width: 100%;
                }
            }

            @media (max-width:576px) {
                width: 100%;

                .descr {
                    padding-left: 0;
                }
            }
        }

        .imgs-wrapper {

            .img-container {
                border-radius: $rounded;
                overflow: hidden;
                width: 80%;
                margin-left: auto;
                
                img {
                    width: 100%;
                }
            }
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column-reverse;
            gap: 1rem;
        }
    }

    #latest-news {
        margin-top: 20rem;
        margin-left: 5rem;

        .fancy-title {
            margin-bottom: 2rem;
        }

        @media (max-width:1199px) {
            margin-top: 10rem;
        }

        @media (max-width:991px) {
            margin-left: 0;
        }
    }
}

.filters-wrapper {
    margin-bottom: 2.6rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    gap: $medium;

    #searchbar-group {
        margin-left: .8rem;
    }
}

#page-conferences {

    .filters-wrapper {
        display: grid;
        grid-template-columns: 25% 25% 30%;

        @media (max-width:1199px) {
            grid-template-columns: repeat(3, 1fr);
        }

        @media (max-width:991px) {
            grid-template-columns: repeat(2, 1fr);

            #searchbar-group {
                grid-column: span 2;
                margin-left: 0;
            }
        }

        @media (max-width:576px) {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }
    }
    #conferences {
        display: flex;
        flex-direction: column;
    }
}

#page-travels,
#page-symposiums {

    #travels,
    #symposiums {
        display: flex;
        flex-direction: column;
        gap: $gigantic;
    }

    .cta-btn {
        margin-top: 5.6rem;
    }
}

#related-events {
    margin-top: $margin-base;
    display: flex;
    gap: 6rem;
    align-items: flex-end;
    width: 100%;
    margin-left: -1.4rem;

    .section-title {
        writing-mode: vertical-rl;
        text-orientation: sideways;
        transform: rotate(180deg);
    }

    .events {
        display: flex;
        flex-direction: column;
        gap: $gigantic;
        width: 100%;
    }

    @media (max-width:767px) {
        margin-top: 6rem;
    }

    @media (max-width:1440px) {
        margin-left: 0;
        flex-direction: column;
        align-items: flex-start;
        gap: 2rem;

        .section-title {
            transform: rotate(0);
            writing-mode: initial;
            text-orientation: initial;
        }
    }
}

#page-about {

    #intro {
        margin-bottom: 2rem;
    }

    .button-wrapper {
        margin-top: 2rem;
    }

    #mission, #history {
        margin-top: $margin-base;
    }

    #mission {
        display: grid;
        grid-template-columns: 42% auto;
        gap: 8.6rem;
        align-items: center;

        img {
            width: 100%;
            border-radius: $rounded;
        }

        .content {
            padding-right: 10%;

            .descr {
                margin-top: 1.8rem;
                max-width: 80%;
                padding-left: 5rem;
            }
        }

        @media (max-width:1199px) {
            gap: 4rem;

            .content {
                margin-top: 4rem;
            }
        }

        @media (max-width:991px) {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;

            .content {
                margin-top: 0;
                padding-right: 0;

                .descr {
                    max-width: 100%;
                }
            }
        }
    }

    .container-fluid.mission {
        @media (max-width:991px) {
            max-width: 90vw;
        }
    }

    #history {

        .container-fluid {
            @media (max-width:576px) {
                max-width: 100% !important;
            }
        }

        .img-container {
            max-width: 1920px;
            margin-inline: auto;
        }

        img {
            width: 100%;
        }

        .container.small-container {
            background-color: white;
            margin-top: -12rem;
            border-radius: $rounded;
            padding: 3.6rem 4.8rem;

            @media (max-width:1199px) {
                margin-top: -8rem;
                padding: 2rem 3rem;
            }

            @media (max-width:991px) {
                margin-top: -6rem;
            }

            @media (max-width:767px) {
                max-width: 85vw;
            }
        }

        h2 {
            margin-bottom: 1.2rem;
        }
    }
}

#page-contact {

    .grid {
        width: 100%;
        max-width: 91%;
        display: grid;
        grid-template-columns: 45% 45%;
        justify-content: space-between;
        gap: 2rem;

        @media (max-width:991px) {
            max-width: 100%;
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 4rem;

            >div {
                width: 100%;
            }
        }
    }

    #addresses {
        display: flex;
        flex-direction: column;
        gap: 3.3rem;
    }

    .address {
        padding-left: 7rem;
        padding-top: 1.7rem;
        padding-bottom: 1.7rem;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            width: 1.4rem;
            height: 100%;
            background-color: $color-gold;
        }

        h2 {
            margin-bottom: $small;
        }

        address {
            margin-bottom: .7rem;
        }
    }

    #form {

        h2 {
            margin-bottom: 2rem ;
        }
    }
}
