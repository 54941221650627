@import "../../assets/styles/scss/variables";

#page-header.standard-header {
  margin-bottom: 6rem;

  @media (max-width:991px) {
    
  }

  .container-fluid {
    @media (max-width:991px) {
      max-width: 90vw;
    }
  }

  &.no-img {
    
    .grid {
      margin-top: $gigantic;
      display: flex;
      flex-direction: column;
      gap: 0;
    }

    .left {
      margin-top: 0;
      padding-left: 15%;
    }
  }

  .grid {
    display: grid;
    grid-template-columns: auto 55%;
    gap: 3.5rem;

    @media (max-width:991px) {
      display: flex;
      flex-direction: column-reverse;
      gap: 2rem;

      .left {
        margin-top: 0;
        padding-left: 0;
      }

      .right {
        width: 100%;
        margin-inline: auto;
      }
    }
  }

  .left {
    padding-left: 10%;
    margin-top: 9rem;

    @media (max-width:1199px) {
      margin-top: 4rem;
    }
  }

  h1 {
    padding-top: 1.4rem;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 5rem;
      height: 2px;
      background-color: $color-gold;
    }
  }

  .descr {
    margin-top: $large;

    a {
      color: $color-gold-dark;
      transition: all .2s linear;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;
      width: fit-content;
    
      &::before {
        content: "\f178";
        font-family: $fontawesome;
        font-weight: 100;
        font-size: 1.2rem;
      }
    
      &:hover {
        color: $color-gold;
      }
    }
  }
}

body.home {

  #page-header.standard-header {

    .grid {
      grid-template-columns: 55% auto;

      @media (max-width:991px) {
        flex-direction: column;
      }
    }

    .left {
      order: 2;
      padding-left: 0;
      padding-right: 0;
      max-width: 35rem;

      @media (max-width:991px) {
        padding-right: 0;
      }
    }

    .right {
      order: 1;
    }
  }
}