@import "../../assets/styles/scss/variables";

#page-header.event-header {
  margin-top: 1.4rem;
  margin-bottom: 4.7rem;
  max-width: 1440px;
  margin-inline: auto;

  .top-header {
    margin-left: 10%;
    max-width: 80%;

    @media (max-width:767px) {
      max-width: 83%;
    }

    @media (max-width:576px) {
      margin-left: 0;
      max-width: 100%;
    }
  }

  @media (max-width:991px) {
    max-width: 100%;
  }

  h1 {
    margin-top: 1.8rem;
    margin-bottom: 1rem;
  }

  ul.infos {
    display: flex;
    flex-wrap: wrap;
    text-transform: uppercase;
    color: $color-gray;
    gap: 1.2rem;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 1.2rem;

      &:not(:first-child)::before {
        content: "";
        width: .45rem;
        height: .45rem;
        border-radius: 50%;
        background-color: $color-gold;
      }
    }

    @media (max-width:576px) {
      flex-direction: column;
      gap: 1rem;

      li {
        &::before {
          display: none;
        }
      }
    }
  }

  .img-container {
    margin-top: 3rem;
    border-radius: $rounded;
    display: none;

    img {
      width: 100%;
    }
  }
}