@import "../../assets/styles/scss/variables";

.news-bloc {
  display: grid;
  grid-template-columns: 33% auto;
  gap: 1.4rem;
  color: $color-text;

  .img-container {
    border-radius: $rounded;
    height: auto;
    overflow: hidden;
    position: relative;
    padding-top: 72%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: all .2s linear;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    padding-right: 2.5rem;

    &::after {
      content: "\f178";
      font-family: $fontawesome;
      font-weight: 100;
      color: $color-gold;
      font-size: 2rem;
      position: absolute;
      right: 0;
      bottom: .5rem;
    }

    h2 {
      margin-bottom: 2rem;
      max-width: 75%;
    }

    p {
      color: $color-gray;
      text-transform: uppercase;
      margin: 0;
    }
  }

  @media (max-width:1199px) {
    display: flex;
    flex-direction: column;
    gap: .5rem;

    .content {

      .fancy-title {
        padding: 0;
        max-width: 100%;
        margin-bottom: .8rem !important;
  
        &::before {
          display: none;
        }
      }
    }

  }

  &:hover {

    .img-container {

      img {
        transform: scale(1.05);
      }
    }
  }
}