@import "../../assets/styles/scss/variables";

.accordion {
  border-bottom: 1px solid $color-gold;
  
  &:last-child {
    border-bottom: none;
  }

  &:first-child {

    .accordion-title {
      padding-top: 0;
    }
  }

  .accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
    padding: $large 0;
    cursor: pointer;


    &:after {
      font-family: $fontawesome;
      content: "\f054";
      transform: rotate(90deg);
      font-weight: 400;
      color: $color-gold;
      font-size: $medium;
      transition: all .2s linear;
    }

    &.is-open {
      &::after {
        transform: rotate(-90deg);
      }
    }
  }

  .accordion-content {
    display: none;
    padding-bottom: $large;
  }
}