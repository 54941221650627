@import "../../assets/styles/scss/variables";

#page-header.member-header {
  margin-top: 4rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 40% 50%;
  gap: 2rem;
  justify-content: space-between;

  @media (max-width:1199px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  @media (max-width:991px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 2rem;
  }


  h1 {
    margin-top: 4rem;
    margin-bottom: $massive;

    @media (max-width:1199px) {
      margin-top: 2rem;
    }
  }

  .descr {
    max-width: 80%;

    @media (max-width:1199px) {
      max-width: 100%;
    }
  }

  .img-container {

    img {
      width: 100%;
    }
  }
}